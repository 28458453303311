<template>
  <div>
    <big-title-color variant-class="dark">
      <template v-slot:text>Realizar<span> pagos</span>.</template>.
    </big-title-color>
    <!-- CARD PARA CANCELAR O MODIFICAR LOS PAGOS AUTOMATICOS -->
    <vx-card class="mt-base" card-background="#E9F8E8" v-if="user.payment_method == 3">
      <h2 class="title-rg-green mb-4">Tu pago automático está activado.</h2>
      <p class="black text-lg mb-4">
        Recuerda contar con fondos suficientes en tu cuenta el día de pago, de lo contrario, <br>no se realizará el pago
        automático y tendrás que realizarlo manualmente.
      </p>
      <div class="flex">
        <p class="black text-lg mb-2"><span class="bold">Pago domiciliado</span> {{ user.direct_billing.bank }} <span
            class="bold">{{ user.direct_billing.clabe }}</span></p>
        <p class="black underline text-sm ml-4 cursor-pointer" @click.stop="showDirectBillingPrompt">Cambiar cuenta</p>
      </div>
    </vx-card>
    <!-- CARD PARA CANCELAR O MODIFICAR LOS PAGOS AUTOMATICOS -->
    <!-- CARD PARA ACTIVAR LOS PAGOS AUTOMATICOS -->
    <vx-card class="mt-base" card-background="#E9F8E8" v-else>
      <h2 class="title-rg-green mb-4">Activa el pago automático.</h2>
      <div class="vx-row justify-between">
        <div class="vx-col sm:w-full md:w-1/2 mb-4 align-self-center">
          <p class="black text-lg">
            Domicilia tu pago y siente la tranquilidad de estar siempre al corriente.
          </p>
        </div>
        <div class="vx-col sm:w-full md:w-1/2 mb-4 text-right">
          <vs-button color="#20B61A" class="sm:px-base px-4" @click="showDirectBillingPrompt">Activar pago
            automático</vs-button>
        </div>

      </div>
    </vx-card>
    <!-- CARD PARA ACTIVAR LOS PAGOS AUTOMATICOS -->

    <!-- NOTIFICACIÓN DEL PAGO -->
    <vx-card class="mt-base" card-background="#FFEE8D" v-if="isMounted && user.payment_method == 3 && isPayDay">
      <div class="flex">
        <div class="items-center mr-2">
          <feather-icon icon="AlertTriangleIcon" class="mr-2 black w-5" />
        </div>
        <div>
          <h4 class="mb-4">El pago automático de tu mensualidad está en proceso.</h4>
          <p class="black text-lg mb-4">
            Estamos procesando tu pago, asegúrate de contar con fondos suficientes en tu cuenta. Si tienes problemas o dudas
            puedes escribirnos en el chat.
          </p>
        </div>
      </div>
    </vx-card>

    <!-- DETALLE DEL PAGO -->
    <PaymentInformation class="mt-base mb-base text-lg" />

    <!-- DOMICILIACIÓN -->
    <LoanDeliveredDetail :show-cards="false" ref="loanDeliveredDetails" />

    <!-- <payment-options></payment-options> -->
    <vx-card class="mt-base" title="Consulta tu plan de pagos.">
      <p class="text-lg">
        Visualiza tu plan de pagos fácilmente. ¡Conoce cuotas, fechas y más con un solo clic!
      </p>
      <vs-button color="primary" class="sm:px-base mt-5 px-4" @click="showPopUpPayments">Ver plan de pagos</vs-button>
    </vx-card>
    <!-- Popup para tabla de amortización consolidada -->
    <vs-popup :active.sync="popUpPaymentsActive" title="Plan de pagos" class="max-width-popup header-primary">
      <applicant-payments-plan project-id="-1" :master-global="true" />
    </vs-popup>
  </div>
</template>
<script>
import ApplicantPaymentsPlan from "@components/applicant/projects/detail/ApplicantPaymentsPlan";
import PaymentInformation from "@views/applicant/onboarding/components/loan-payments/PaymentInformation.vue";
import LoanDeliveredDetail from "@views/applicant/home/LoanDeliveredDetail.vue";

export default {
  components: {
    ApplicantPaymentsPlan,
    PaymentInformation,
    LoanDeliveredDetail
  },
  data() {
    return {
      contractCancelDirectBillingType: 20,
      popUpPaymentsActive: false,
      popUpDirectBillingCancelContract: false,
      currentPayment: null,
      master: null,
      currentMasterPayment: null,
      currentDate: new Date,
      currentFullDate: null,
      isMounted: false
    }
  },
  async mounted() {
    this.setCurrentDate();
    await this.getLoans();
    this.isMounted = true;
  },
  computed: {
    isPayDay() {
      return this.currentMasterPayment.payment_date === this.currentFullDate;
    }
  },
  methods: {
    setCurrentDate() {
      let m = this.currentDate.getMonth() + 1;
      const y = this.currentDate.getFullYear().toString();
      const d = this.currentDate.getDate();
      if (m < 10) {
        m = `0${m}`;
      }
      this.currentDate = `${y}-${m}`
      this.currentFullDate = `${y}-${m}-${d}`
    },
    showPopUpPayments() {
      // se genera log de accion
      this.saveApplicantLogAction("LC19-36");
      this.popUpPaymentsActive = true;
    },
    showPopUpCancelDirectBilling() {
      // se pide la geolocalizacion para la operacion
      this.$refs.rgGeolocator.askForGeolocation();
      this.popUpDirectBillingCancelContract = true;
    },
    async goToAutomaticPayments() {
      await this.$router.push({ name: 'pagoAutomaticoSolicitante' });
    },
    async cancelDirectBilling() {
      this.showLoading(true, "Guardando información...");
      try {
        let payload = {
          geolocation: this.CurrentGeolocationData,
          contractType: this.contractCancelDirectBillingType
        };
        await axios.post("/api/v1/applicant/" + this.ApplicantId + "/cancelDirectBilling", payload);
        await this.getUserInfo(this.UserRole);
        this.showLoading(false);
        this.popUpDirectBillingCancelContract = false;
        await this.$router.go();
      } catch (error) {
        this.showLoading(false);
        if (typeof error.response.data.message !== 'undefined') {
          this.errorNotifDialog('Operación fallida', error.response.data.message);
        } else {
          this.failedOperationNotif();
        }
      }
    },
    showDirectBillingPrompt() {
      this.$refs.loanDeliveredDetails.showDirectBillingPrompt = true;
    },
    async getLoans() {
      try {
        const res = await axios.get(`api/loan-onboarding/get/${this.ApplicantId}/loans`);
        const loans = res.data.loans;
        await this.getAllPayments(loans[0].id);
      } catch (error) {
        this.failedOperationNotif("Algo salió mal.");
      }
    },
    async getAllPayments(loanId) {
      const res = await axios.get(`api/cobranza/proyectos/${loanId}/master`);
      this.master = res.data;
      this.getNextPayment();
    },
    getNextPayment() {
      // Sí es master usa la tabla consolidada
      const paymentTable = this.master.master
      const payment = paymentTable.filter(x => x.payment_date.includes(this.currentDate));
      this.currentMasterPayment = payment[0];
    },
  }
}
</script>

<style>
.con-vs-dialog {
  z-index: 100000 !important;
}
</style>